@import "base";
@import "../../components/cells/Layout/variables";
@import "../../../shared/style/base";

.page {
  .tab_wrapper {
    @include flex(flex-start, flex-start, column);

    @include pc {
      gap: ptr(25);
      margin-bottom: ptr(24);
    }

    .tab {
      min-width: ptr(320);
      margin-bottom: 0;
    }
  }

  .process_step_count_wrap {
    margin-bottom: ptr(24);
    @include mobile {
      margin: 24px 20px;
    }
  }

  .table_wrapper {
    background-color: $gray-5;

    &.empty {
      overflow: hidden;
    }
    @include pc {
      padding: ptr(24) ptr(24) ptr(32);
      border-radius: ptr(8);
    }

    .search_wrap {
      display: flex;
      label {
        background-color: $white;
      }
      column-gap: ptr(8);

      @include pc {
        margin-bottom: ptr(24);
        label {
          max-width: ptr(256);
        }
        .search_button {
          width: ptr(60);
          border-radius: ptr(4);
        }
        .search_dropdown {
          width: ptr(128);
        }
      }

      @include mobile {
        padding: 12px 12px 0;
        .search_button {
          width: ptr(60);
          border-radius: ptr(4);
        }
        .status_dropdown {
          width: 100%;
        }
        .search_dropdown {
          width: ptr(128);
        }
      }
    }

    .table {
      margin-bottom: ptr(24);
      border: $gray-10;
      border-radius: ptr(8);

      @include mobile {
        margin: ptr(20) ptr(16);
      }

      .table_cell {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .commission_table_cell {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: unset;
      }

      .commission_state_table_cell {
        padding: ptr(3) ptr(8) ptr(4) ptr(8);
        border-radius: ptr(4);
      }

      .commission_state_pending_table_cell {
        background-color: $gray-5;
        color: $gray-80;
      }

      .commission_state_refunded_table_cell {
        background-color: $red-10;
        color: $red-80;
      }

      .commission_state_accepted_table_cell {
        background-color: $blue-10;
        color: $blue-100;
      }
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $gray-5;
      @include mobile {
        padding-bottom: ptr(20);
      }
    }
  }
}
