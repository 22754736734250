@import "base";
.layout {
  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ptr(40) 0;
    gap: ptr(32);

    html[is_mobile="true"] & {
      width: 100%;
      padding: ptr(32) ptr(16);
    }
  }

  .congrats {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ptr(400);
    height: ptr(240);

    html[is_mobile="true"] & {
      width: ptr(320);
      height: ptr(214);
    }

    > img {
      height: 100%;
      object-fit: cover;
    }
  }

  .guide {
    display: flex;
    flex-direction: column;
    gap: ptr(8);
    text-align: center;

    .content {
      color: $gray-50;
    }
  }

  .terms {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: ptr(16) 0;
    gap: ptr(12);

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .splitter {
      width: 100%;
      height: 1px;
      background-color: $gray-20;
    }

    .link {
      color: $gray-50;
      text-decoration: underline;
    }
  }
}
