@import "base";
// pc
html[is_mobile="false"] {
  .date_picker_container {
    background: inherit;
    &.disabled {
      background: inherit;
    }
  }

  .body {
    position: relative;
    margin-bottom: ptr(40);
    background-color: inherit;
  }

  .overViewSection {
    display: flex;
    width: 100%;
    margin-bottom: ptr(32);
    column-gap: ptr(8);
  }

  .buttonSection {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: ptr(12);
  }

  .tableSection {
    width: 100%;
    border: 1px solid $gray-10;
    border-radius: ptr(8);
  }

  .excelButton {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: ptr(116);
    min-height: ptr(40);
    column-gap: ptr(4);
    border: 1px solid $gray-10;
    border-radius: ptr(4);
    background-color: $white;
    .excelIconWrap {
      display: inline-block;
      width: ptr(16);
      height: ptr(16);
      color: $green-100;
    }

    &.disabled {
      background-color: $gray-5;
      color: $gray-50;
      pointer-events: none;
      .excelIconWrap {
        color: $gray-50;
      }
    }
  }

  .productNameCell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: ptr(8) ptr(8) ptr(8) ptr(16);
    column-gap: ptr(12);
    color: $gray-80;
    cursor: pointer;
  }

  .productName {
    display: -webkit-box;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .thumbnailWrap {
    flex-shrink: 0;
    width: ptr(40);
    height: ptr(40);
    overflow: hidden;
    border-radius: ptr(4);
  }

  .arrowButtonWrap {
    flex-shrink: 0;
    width: ptr(16);
    height: ptr(16);
    color: $gray-80;
  }

  .childTable {
    display: flex;
    flex-direction: column;
    width: 100%;
    transform-origin: top;
    background-color: $gray-1;
  }

  .childRow {
    display: grid;
    box-sizing: border-box;
    grid-template-columns:
      minmax(280px, 1fr)
      repeat(2, minmax(124px, 1fr))
      repeat(4, minmax(112px, 1fr))
      repeat(2, minmax(160px, 1fr));
    align-items: center;
    width: 100%;
    min-height: ptr(56);
    padding: 8px 0;
    border-bottom: ptr(1) solid $gray-5;
    color: $gray-80;
    justify-items: center;

    .withCompanyProfit {
      grid-template-columns:
        minmax(280px, 1fr)
        repeat(2, minmax(124px, 1fr))
        repeat(4, minmax(112px, 1fr))
        repeat(3, minmax(160px, 1fr));
    }
  }

  .truncate2Lines {
    display: -webkit-box;
    width: 100%;
    padding: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .table {
    display: flex;
    flex-direction: column;
    border-radius: ptr(8);
    color: $black;
  }

  .header {
    border-radius: ptr(8) ptr(8) 0 0;
    background-color: $gray-5;
    color: $black;
    .header_cell {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      column-gap: ptr(4);
      color: $gray-80;
      &.type_filter,
      &.sort {
        cursor: pointer;
      }
      &.active {
        color: $blue-100;
      }

      &.type_filter {
        position: relative;
      }
    }

    .header_text {
      color: inherit;
    }

    .header_icon_wrap {
      display: inline-block;
      width: ptr(16);
      height: ptr(16);
    }
  }

  .row {
    display: flex;
    border-bottom: 1px solid $gray-5;

    &:hover {
      background-color: $gray-5;
    }
  }

  .cell {
    padding: ptr(8);
    color: $gray-80;

    a {
      color: inherit !important;
      text-decoration: underline;
    }
  }

  .totalRowContainer {
    width: 100%;
    height: 56px;
    margin-top: -1px;
    overflow: hidden;
    border-radius: 0 0 8px 8px;
    background-color: $blue-10;
  }

  .totalRow {
    display: grid;
    grid-template-columns:
      minmax(240px, 1fr)
      repeat(2, minmax(124px, 1fr))
      repeat(4, minmax(112px, 1fr))
      repeat(2, minmax(160px, 1fr));
    grid-template-rows: 56px;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $gray-5;
    justify-items: center;

    &.withCompanyProfit {
      grid-template-columns:
        minmax(240px, 1fr)
        repeat(2, minmax(124px, 1fr))
        repeat(4, minmax(112px, 1fr))
        repeat(3, minmax(160px, 1fr));
    }

    .cell {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 8px;
      color: $black;

      &:first-child {
        justify-content: flex-start;
        padding-left: 44px;
      }
    }
  }
}

// mo
html[is_mobile="true"] {
  .date_picker_container {
    display: flex;
    width: 100%;
    margin-top: ptr(16);
    &.disabled {
      background: inherit;
    }

    & > div {
      flex: 1;
      & > button {
        width: 100%;
      }
    }
  }

  .body {
    margin-top: -24px;
    padding: 0 16px;
  }

  .dropdownSection {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-top: 8px;
    .dropdownWrap_level1 {
      position: relative;
      z-index: 1;
      min-height: 40px;
    }
    .dropdownWrap_level2 {
      position: relative;
      z-index: 0;
      min-height: 40px;
    }
    .selectedText {
      display: flex;
      column-gap: 12px;
      .title {
        color: $gray-80;
      }
      .content {
        color: $black;
      }
    }
  }

  .tableSection {
    display: flex;
    flex-direction: column;
    padding: 32px 0;
    row-gap: 24px;
  }
}
