@import "base";
@import "../../../../shared/style/base";

.normal_table {
  border-radius: ptr(8);
  color: $black;

  .header {
    border-bottom: 1px solid $black;
  }

  .row {
    border-bottom: 1px solid $gray-5;

    &:hover {
      background-color: $gray-5;
    }
  }

  .cell {
    padding: ptr(8);

    a {
      color: inherit !important;
      text-decoration: underline;
    }
  }

  .empty {
    padding-top: ptr(162);
    padding-bottom: ptr(312);
    color: $gray-50;
    text-align: center;
  }

  .image_empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: ptr(160) 0;
    .imgWrap {
      width: ptr(160);
      height: ptr(160);
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    span {
      color: $gray-80;
    }
  }
}

.compact_table {
  color: $black;

  .header {
    border-radius: ptr(8);
    background-color: $gray-5;
  }

  .row {
    margin-top: ptr(16);

    &:hover {
      background-color: $gray-5;
    }
  }

  .cell {
    padding: ptr(8);

    a {
      color: inherit !important;
      text-decoration: underline;
    }
  }

  .empty {
    padding-top: ptr(60);
    padding-bottom: ptr(118);
    color: $gray-50;
    text-align: center;
  }
}
