@import "base";
.my_page_tabs {
  max-width: $pc-layout-body-max-width;
  margin: 0 auto;
}

.my_page_tab_buttons {
  width: 100%;
  @include flex(center, center);
  span {
    font-family: $font_family_en-unica;
    font-size: ptr(16);
    font-style: normal;
    font-weight: $font-weight-bold;
    letter-spacing: ptr($font_letter_spacing_base);
    line-height: calc(22 / 16);
  }
}

.my_page_tab_view {
  width: 100%;
}

.my_page_tab_button {
  width: 100%;
  text-align: center;

  &[data-active="true"] {
    border-bottom: 1px solid $black;
    color: $black;
  }

  &[data-active="false"] {
    border-bottom: 1px solid $gray-20;
    color: $gray-50;
  }
}

html[is_mobile="true"] {
  .my_page_tabs {
    margin: ptr(60) 0 0;
  }

  .my_page_tab_view {
    padding: 0;
  }

  .my_page_tab_button {
    height: ptr(40);
  }
}

html[is_mobile="false"] {
  .my_page_tabs {
    margin: ptr(24) auto 0;
    padding: 0 ptr(32);
  }

  .my_page_tab_view {
    padding: 0 0 0;
  }

  .my_page_tab_button {
    padding: ptr(12) 0;
  }
}
