@import "base";
html[is_mobile="true"] {
  .body {
    min-height: ptr(695);
  }
}

html[is_mobile="false"] {
  .body {
    min-height: ptr(960);
  }
}
