@import "base";
.body {
  max-width: $pc-layout-body-max-width;
  margin: 0 auto;
}

html[is_mobile="true"] {
  .body {
    margin: ptr(60) 0 0;
  }
}

html[is_mobile="false"] {
  .body {
    margin: ptr(24) auto 0;
    padding: 0 ptr(32);
  }
}
