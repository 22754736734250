@import "base";
@import "../../../../../shared/style/base";

.tab_container {
  margin-top: ptr(12);
  margin-bottom: ptr(16);
  cursor: grab !important;

  &.dragging {
    cursor: grabbing !important;
  }
}

.qna_tab {
  flex: 1;
}

.section_title {
  display: flex;
  align-items: center;
  column-gap: 4px;
  .count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: $blue-100;
    color: $white;
  }
}

.qna_container {
  display: grid;
  min-height: ptr(176);
  margin-top: ptr(20);
  color: $gray-80;

  &:not(.empty) {
    grid-auto-rows: ptr(24);
    grid-gap: ptr(12) ptr(8);
    grid-template-columns: max-content 1fr;
    align-items: center;
  }

  &.hidden {
    display: none;
  }

  &.empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    color: inherit !important;
    text-decoration: underline;
  }

  .title {
    @include lineLimit(1);

    color: inherit !important;
    text-decoration: underline;
    white-space: normal;
  }

  .empty_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .imgWrap {
      width: ptr(100);
      height: ptr(100);
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    span {
      color: $gray-80;
    }
  }
}
