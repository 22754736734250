@import "base";
html[is_mobile="false"] {
  .page {
    width: 100%;
    min-width: $pc-layout-body-min-width;
    max-width: $pc-layout-body-max-width;
    margin-top: ptr(160);
    margin-right: auto;
    margin-left: auto;
    padding: 0 ptr(32);
  }

  .tabs {
    padding: ptr(24) 0;
  }

  .body {
    display: flex;
    gap: ptr(18);
  }

  .product_section {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ptr(24);
  }

  .sort_wrapper {
    display: flex;
    flex-direction: column;
    gap: ptr(16);
    width: 100%;
  }

  .sort_container {
    display: flex;
    z-index: 2;
    align-items: center;
    justify-content: space-between;

    .right_panel {
      display: flex;
      gap: ptr(16);
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ptr(56);
  }

  .commission_widget {
    margin-top: ptr(59);
  }
}

html[is_mobile="true"] {
  .page {
    width: 100%;
    margin-top: ptr(124);
  }

  .title {
    padding: ptr(24) ptr(16);
  }

  .tab {
    display: flex;
    flex: 1 1 0;
    align-items: center;
    justify-content: center !important;
  }

  .ui {
    padding: ptr(48) ptr(16) 0;
  }

  .commission_title {
    padding: ptr(24) ptr(16) 0;
  }

  .commission_widget {
    margin-top: 0;
  }

  .product_section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 ptr(16);
  }

  .sort_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ptr(4);

    .right_panel {
      display: flex;
      align-items: center;
      gap: ptr(8);
      padding: ptr(8) 0;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ptr(48);
  }

  .support {
    .ui {
      padding: 0;
    }

    .title {
      padding: ptr(24) ptr(16) 0;
    }
  }
}
