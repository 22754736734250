@import "base";
.dropdown {
  position: relative;
  width: 100%;
  min-height: ptr(40);
  border: ptr(1) solid $gray-10;
  border-radius: ptr(8);
  background: $white;
  white-space: nowrap;

  &[data-fit="true"] {
    width: fit-content;
    min-width: unset;
  }

  &[data-open="true"] {
    border-top: ptr(1) solid $gray-10;
    border-right: ptr(1) solid $gray-10;
    border-left: ptr(1) solid $gray-10;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    .dropdown_options {
      display: block;
    }
  }

  &:disabled {
    background: $gray-5;

    .dropdown_selected {
      color: $gray-50;

      .dropdown_icon {
        color: $gray-50;
      }
    }
  }

  .dropdown_selected {
    @include flex(space-between, center);

    padding: ptr(8) ptr(12);
    color: $black;
    cursor: pointer;
    gap: ptr(16);

    .dropdown_icon {
      @include flex(center, center);

      width: ptr(16);
      height: ptr(16);
      color: $black;

      & > svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .dropdown_options {
    display: none;
    position: absolute;
    z-index: 10;
    left: -#{ptr(1)};
    width: calc(100% + #{ptr(2)});
    border-right: ptr(1) solid $gray-10;
    border-bottom: ptr(1) solid $gray-10;
    border-left: ptr(1) solid $gray-10;
    border-bottom-right-radius: ptr(8);
    border-bottom-left-radius: ptr(8);
    background: $white;

    .dropdown_option {
      padding: ptr(8) ptr(12);
      cursor: pointer;

      &:hover {
        background-color: $blue-10;
      }

      &:nth-last-of-type(1) {
        border-bottom-right-radius: ptr(8);
        border-bottom-left-radius: ptr(8);
      }
    }
  }

  & > input {
    display: none;
  }
}
